import React, {useState} from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Icon, Section, Hr, Strong, Link, LinkBox, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";

export default (() => {
	const [email, setEmail] = useState('')
	async function sendEmail() {
		if (isValidEmail(email)) {
			const requestOptions = {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({email}),
				mode: "cors",
			};
			await fetch('https://everweave.ai/baconbreeze/emails/add', requestOptions).
				then(res => {
					console.log(res.status)
					if (res.status===200) {
						setEmail('')
						alert("You are now subscribed to our newsletter. Thank you!")
					}
				}).
				catch(e => console.log(e))
		}
		else {
			alert("Please enter a valid email");
		}
	}
	function isValidEmail(email) {
		// Define a regular expression pattern for email validation.
		const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return pattern.test(email);
	}
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Everweave - Play D&D online
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/favicon.png?v=2024-07-09T17:04:35.953Z"} type={"image/x-icon"} />
		</Helmet>
		<Section
			background="linear-gradient(-0deg,#080d13 4.8%,rgba(4, 8, 12, 0) 35.2%),url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/10.jpg?v=2024-07-03T01:15:13.007Z) 0% 5%/100% scroll"
			sm-padding="10% 0 10px 0"
			color="--light"
			font="--base"
			height="105vh"
			flex-direction="column"
			justify-content="space-between"
			md-background="linear-gradient(180deg,rgba(0,0,0,0) 77.1%,#080d13 100%),url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/10.jpg?v=2024-07-03T01:15:13.007Z) 0% 52%/cover scroll"
			sm-background="linear-gradient(180deg,rgba(0,0,0,0) 75.7%,#080d13 94.3%),url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/10.jpg?v=2024-07-03T01:15:13.007Z) 52% 52%/cover scroll"
			sm-display="flex"
			sm-justify-content="center"
			sm-align-items="center"
			sm-flex-direction="column"
			sm-flex-wrap="no-wrap"
			sm-position="static"
			sm-height="90vh"
			overflow-x="hidden"
			width="100%"
			align-items="center"
			sm-overflow-x="hidden"
		>
			<Override
				slot="SectionContent"
				align-content="flex-start"
				flex-direction="column"
				flex-wrap="wrap-reverse"
				flex="0 1 auto"
				order="1"
				align-self="center"
				height="96vh"
				sm-align-items="center"
				sm-overflow-x="hidden"
				sm-overflow-y="visible"
				sm-flex-direction="column"
				sm-margin="0px 0px 0px 0px"
				sm-align-self="stretch"
				sm-flex="0 1 auto"
				sm-order="1"
				justify-content="space-between"
				align-items="center"
				display="flex"
				width="100% border-box"
				sm-display="flex"
				sm-justify-content="center"
				sm-height="100%"
				sm-max-width="auto"
				sm-flex-wrap="no-wrap"
				sm-align-content="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				sm-display="flex"
				sm-align-items="center"
				sm-flex-direction="column"
				sm-overflow-x="hidden"
				sm-height="100%"
				sm-justify-content="space-between"
				sm-align-content="center"
				height="100%"
				align-items="center"
				display="flex"
				justify-content="space-between"
				flex-direction="column"
			>
				<Box
					display="flex"
					align-items="center"
					justify-content="center"
					align-content="center"
					flex-wrap="no-wrap"
					sm-align-items="center"
					sm-flex-direction="column"
					flex-direction="column"
					width="100%"
					order="-1"
					align-self="center"
					sm-display="flex"
					sm-margin="35% 0px 0% 0px"
					sm-width="100%"
					sm-mix-blend-mode="normal"
					sm-height="100% border-box"
				>
					<Image
						src="https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/Label2.png?v=2024-07-08T07:56:54.006Z"
						display="block"
						padding="12% 0px 0px 0px"
						margin="px 0px -50 0px"
						sm-width="175%"
						sm-text-align="center"
						sm-align-items="center"
						sm-display="block"
						sm-justify-content="center"
						sm-align-content="center"
						sm-flex-direction="column"
						sm-flex-wrap="no-wrap"
						sm-overflow-x="hidden"
						sm-position="static"
						sm-order="0"
						sm-align-self="center"
						text-align="center"
						align-self="center"
						overflow="visible"
						width="100%"
						sm-padding="0px 0px 0px 0px"
						sm-margin="0px 0px 0px 0px"
						sm-height="auto"
						srcSet="https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/Label2.png?v=2024-07-08T07%3A56%3A54.006Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/Label2.png?v=2024-07-08T07%3A56%3A54.006Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/Label2.png?v=2024-07-08T07%3A56%3A54.006Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/Label2.png?v=2024-07-08T07%3A56%3A54.006Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/Label2.png?v=2024-07-08T07%3A56%3A54.006Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/Label2.png?v=2024-07-08T07%3A56%3A54.006Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/Label2.png?v=2024-07-08T07%3A56%3A54.006Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text
						as="h1"
						font="normal normal 300 20px/1.2 Beaufort for LOL"
						md-font="--base"
						text-align="center"
						position="relative"
						margin="-9% 0 0px 0"
						sm-font="--base"
						sm-margin="-7vh 0 0% 0"
						sm-text-shadow="0 2px 2px #020202"
						text-shadow="0px 0px 2px #020202"
					>
						APP THAT LETS YOU PLAY D&D ANYWHERE, ANYTIME
					</Text>
					<LinkBox
						align-items="center"
						justify-content="center"
						target="_blank"
						href="https://play.google.com/store/apps/details?id=com.endgame.everweave"
						sm-margin="35% 0px 5% 0px"
						margin="23% 0px 2% 0px"
					>
						<Image
							src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1024px-Google_Play_Store_badge_EN.svg.png'
							display="block"
							object-fit="contain"
							width="43%"
							border-radius="20px"
							hover-box-shadow="0 0 15px 5px rgba(255, 124, 34, 0.58)"
							sm-border-radius="14px"
							sm-box-shadow="0 0 15px 0 --color-orange"
							md-box-shadow="0 0 10px 5px rgba(255, 124, 34, 0.65)"
							border-color="--color-orange"
							border-width="3px"
							border-style="inset"
							sm-width="70%"
							sm-margin="0px 0px 0px 0px"
						/>
					</LinkBox>
				</Box>
				<Components.QuarklycommunityKitAnimation
					animation="↓ Slide In"
					delay="2s"
					test={false}
					duration="3s"
					timingFunction="ease"
					iteration="infinite"
					sm-margin="0% 0px 0px 0px"
					sm-align-items="center"
					sm-display="flex"
					sm-justify-content="center"
					sm-flex-direction="column"
				>
					<Box
						text-align="center"
						text-shadow="1px 1px 5px #0d0d0d"
						position="static"
						flex="0 1 auto"
						order="1"
						align-self="auto"
						overflow-x="visible"
						display="block"
						sm-display="flex"
						sm-align-items="center"
						sm-flex-direction="column"
						sm-justify-content="flex-end"
						sm-flex-wrap="no-wrap"
						sm-margin="0px 0px 0px 0px"
					>
						<Text
							text-transform="uppercase"
							color="rgba(247, 251, 255, 0.58)"
							font="normal 300 12px/1.5 Beaufort for LOL"
							sm-display="grid"
							margin="16px 0px 2px 0px"
						>
							Learn more
						</Text>
						<Icon
							category="md"
							icon={MdArrowDownward}
							color="rgba(247, 251, 255, 0.58)"
							align-content="flex-end"
							justify-content="center"
							size="20px"
							sm-display="grid"
							sm-align-items="center"
							sm-justify-items="center"
							sm-align-content="center"
							margin="0 auto"
						/>
					</Box>
				</Components.QuarklycommunityKitAnimation>
			</Box>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="40px 0 40px 0"
			sm-padding="10% 0 10% 0"
			background="#080d13"
			md-background="#080d13"
			height="auto"
			flex-direction="column"
		>
			<Override slot="SectionContent" justify-content="center" align-items="center" sm-width="90%" />
			<Text
				as="h5"
				font="normal 300 32px/1.5 Beaufort for LOL"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
				sm-font="normal 300 28px/1.5 Beaufort for LOL"
			>
				it's your turn. what do you do?
			</Text>
			<Hr
				min-height="10px"
				margin="2% 0px 0px 0px"
				width="60%"
				sm-width="90%"
				sm-margin="4% 0px 0px 0px"
			/>
			<Text
				as="p"
				font="normal 300 22px/1.5 Beaufort for LOL"
				margin="2% 0 2% 0"
				md-font="--base"
				padding="0px 60px 0px 60px"
				sm-padding="0px 0px 0px 0px"
				width="90%"
				sm-font="normal 300 18px/1.5 Beaufort for LOL"
				sm-width="auto"
				sm-max-width="95%"
			>
				Everweave merges the rich storytelling of D&D with modern technology, offering a unique roleplaying experience. Create your character in minutes and dive into endless, dynamic adventures. Our intelligent Dungeon Master handles storytelling, game mechanics, and brings NPCs to life, allowing you to focus on your journey and creative decisions.{"\n"}
				<br />
				<br />
				Currently in early access, Everweave is available as an Android app exclusively for our patrons. Stay tuned for our web version, planned for release this November{" "}
				<br />
				Stay tuned for our web version, planned for release this August.
			</Text>
		</Section>
		<Section
			padding="2% 0 2% 0"
			background="#080d13"
			pointer-events="none"
			height="auto"
			md-background="#080d13 url() 0% 100%/cover"
			md-height="110vh"
			sm-background="#080d13"
			sm-height="auto"
			sm-display="flex"
			sm-padding="10% 0 10% 0"
		>
			<Override
				slot="SectionContent"
				md-height="90vh"
				md-justify-content="space-between"
				md-margin="-50px auto 0px auto"
				sm-display="flex"
				sm-overflow-x="hidden"
				sm-overflow-y="visible"
				sm-margin="0px auto 0px auto"
				sm-justify-content="center"
				sm-align-items="center"
				sm-flex-direction="column"
				sm-height="inherit"
			/>
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="-6vh 0px 0px 0px"
				align-items="center"
				md-margin="0px 0px 0px 0px"
				sm-align-items="center"
				sm-flex-direction="column"
				sm-justify-content="center"
				sm-height="auto"
				sm-margin="0px 0px 5% 0px"
			>
				<Text
					margin="3% 0px 1% 0px"
					font="normal 900 60px/1.2 Beaufort for LOL"
					color="--light"
					md-text-align="center"
					sm-font="normal 900 36px/1.2 Beaufort for LOL"
					text-align="center"
					sm-margin="0px 0px 1% 0px"
				>
					KEY FEATURES
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="normal 300 16px/1.5 Beaufort for LOL"
					color="--light"
					md-text-align="center"
					text-align="center"
					max-width="480px"
					sm-font="normal 300 18px/1.5 Beaufort for LOL"
					sm-max-width="90%"
				>
					Just a few of the key elements that make Everweave special.
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="4%"
				md-grid-template-columns="1fr"
				margin="3% 0px 0px 0px"
				md-display="flex"
				sm-display="flex"
				sm-text-align="center"
				sm-align-items="center"
				sm-justify-content="space-around"
				align-self="center"
				justify-content="center"
				align-items="center"
				justify-items="center"
				sm-height="auto"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				md-grid-row-gap="2%"
				md-flex-direction="row"
				sm-flex-direction="column"
				sm-overflow-x="hidden"
				sm-align-content="center"
				sm-overflow-y="visible"
				sm-margin="0px 0px 5% 0px"
			>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					background="--color-darkL2 url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/ai.jpg?v=2024-07-03T04:00:19.058Z) 0% 0%/100%"
					border-radius="24px"
					justify-content="center"
					flex-direction="column"
					display="flex"
					hover-transform="scale(1.05)"
					hover-transition="all 0.2s ease 0.0s"
					transition="all 0.2s ease 0.0s"
					hover-background="#1d242a url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/ai.jpg?v=2024-07-03T04:00:19.058Z) 0% 0%/100%"
					width="90%"
					border-width="3px"
					border-style="ridge"
					border-color="--color-orange"
					mix-blend-mode="normal"
					align-items="center"
					flex-wrap="no-wrap"
					align-content="flex-start"
					grid-template-areas="&quot;a b&quot;\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\n&quot;c c&quot;"
					grid-template-columns="50px"
					grid-template-rows="1fr, 12fr"
					md-width="30%"
					md-height="65%"
					md-text-align="center"
					sm-width="95%"
					sm-align-items="center"
					sm-justify-content="center"
					sm-height="auto"
					sm-padding="15px 25px 15px 25px"
					hover-box-shadow="0px 0px 25px 0 --color-dark"
					md-padding="45px 25px 45px 25px"
					sm-margin="0px 0px 5% 0px"
				>
					<Text
						margin="0px 0px 30px 0px"
						color="--light"
						font="normal 700 34px/1.2 Beaufort for LOL"
						lg-text-align="center"
						display="flex"
						justify-content="flex-start"
						text-align="center"
						md-font="normal 700 16px/1.2 --fontFamily-sans"
						md-text-align="center"
						sm-font="normal 700 18px/1.2 Beaufort for LOL"
						sm-align-items="center"
						sm-justify-content="center"
						sm-margin="0px 0px 1% 0px"
					>
						DYNAMIC DUNGEON MASTER
					</Text>
					<Text
						color="--light"
						font="normal 300 16px/1.5 Beaufort for LOL"
						lg-text-align="center"
						flex="1 0 auto"
						display="block"
						grid-area="c"
						text-align="center"
						width="120%"
						md-font="normal 300 12px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						md-text-align="center"
						md-margin="5% 0px 0px 0px"
						sm-font="--base"
						sm-margin="1% auto0px 0px auto"
						sm-width="95%"
						margin="15% 0px 16px 0px"
					>
						Experience dynamic storytelling where the Dungeon Master reacts to your choices, narrates encounters, and controls NPCs, allowing you to play anytime and anywhere without the need to search for a party or worry about RPG horror stories.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					background="--color-darkL2 url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/mechanics.jpg?v=2024-07-03T04:15:08.296Z) 0% 0%/100%"
					border-radius="24px"
					justify-content="center"
					flex-direction="column"
					display="flex"
					hover-background="#1d242a url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/mechanics.jpg?v=2024-07-03T04:15:08.296Z) 0% 0%/100%"
					hover-transform="scale(1.05)"
					hover-transition="all 0.2s ease 0.0s"
					transition="all 0.2s ease 0.0s"
					hover-box-shadow="0px 0px 25px 0 --color-dark"
					width="90%"
					border-style="ridge"
					border-color="--color-orange"
					border-width="3px"
					mix-blend-mode="normal"
					align-content="flex-start"
					align-items="center"
					flex-wrap="no-wrap"
					grid-template-areas="&quot;a b&quot;\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\n&quot;c c&quot;"
					grid-template-columns="50px"
					grid-template-rows="1fr, 12fr"
					md-height="65%"
					md-text-align="center"
					md-width="30%"
					sm-align-items="center"
					sm-justify-content="center"
					sm-width="95%"
					sm-height="auto"
					sm-padding="15px 25px 15px 25px"
					height="100%"
					md-padding="45px 25px 45px 25px"
					sm-margin="0px 0px 5% 0px"
				>
					<Text
						margin="0px 0px 30px 0px"
						color="--light"
						font="normal 700 34px/1.2 Beaufort for LOL"
						lg-text-align="center"
						display="flex"
						justify-content="center"
						text-align="center"
						md-font="normal 700 16px/1.2 --fontFamily-sans"
						md-text-align="center"
						sm-font="normal 700 18px/1.2 Beaufort for LOL"
						sm-align-items="center"
						sm-justify-content="center"
						sm-margin="0px 0px 1% 0px"
						sm-flex-direction="column"
					>
						D&D{" "}
						<br />
						MECHANICS
					</Text>
					<Text
						margin="15% 0px 0px 0px"
						color="--light"
						font="normal 300 16px/1.5 Beaufort for LOL"
						lg-text-align="center"
						flex="1 0 auto"
						display="block"
						grid-area="c"
						text-align="center"
						width="120%"
						md-text-align="center"
						md-margin="5% 0px 0px 0px"
						md-font="normal 300 12px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						sm-font="normal 300 16px/1.5 Beaufort for LOL"
						sm-margin="1% auto0px 0px auto"
						sm-width="95%"
					>
						Deeply integrated classic D&D features
including dice rolls, classes, character sheet, leveling and theater of mind turn-based combat with more classic and adapted systems to appear as we grow.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					background="--color-darkL2 url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/memory.jpg?v=2024-07-03T04:16:32.817Z) 0% 0%/100%"
					border-radius="24px"
					justify-content="center"
					flex-direction="column"
					display="flex"
					hover-background="#1d242a url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/memory.jpg?v=2024-07-03T04:16:32.817Z) 0% 0%/100%"
					hover-transform="scale(1.05)"
					hover-transition="all 0.2s ease 0.0s"
					transition="all 0.2s ease 0.0s"
					hover-box-shadow="0px 0px 25px 0 --color-dark"
					width="90%"
					border-color="--color-orange"
					border-style="ridge"
					border-width="3px"
					mix-blend-mode="normal"
					align-content="flex-start"
					align-items="center"
					flex-wrap="no-wrap"
					grid-template-areas="&quot;a b&quot;\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\n&quot;c c&quot;"
					grid-template-columns="50px"
					grid-template-rows="1fr, 12fr"
					md-height="65%"
					md-text-align="center"
					md-width="30%"
					sm-width="95%"
					sm-height="auto"
					sm-padding="15px 25px 15px 25px"
					height="100%"
					md-padding="45px 25px 45px 25px"
					sm-margin="0px 0px 5% 0px"
				>
					<Text
						margin="0px 0px 30px 0px"
						color="--light"
						font="normal 700 34px/1.2 Beaufort for LOL"
						lg-text-align="center"
						display="flex"
						justify-content="flex-start"
						text-align="center"
						md-font="normal 700 16px/1.2 --fontFamily-sans"
						md-text-align="center"
						sm-font="normal 700 18px/1.2 Beaufort for LOL"
						sm-justify-content="center"
						sm-align-items="center"
						sm-margin="0px 0px 1% 0px"
					>
						LONG-TERM MEMORY
					</Text>
					<Text
						margin="15% 0px 0px 0px"
						color="--light"
						font="normal 300 16px/1.5 Beaufort for LOL"
						lg-text-align="center"
						flex="1 0 auto"
						display="block"
						grid-area="c"
						text-align="center"
						width="120%"
						md-font="normal 300 12px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						md-margin="5% 0px 0px 0px"
						md-text-align="center"
						sm-font="--base"
						sm-margin="1% auto0px 0px auto"
						sm-width="95%"
					>
						The built-in long-term memory system allows your Dungeon Master to remember the most important details of your adventure even after hundreds and thousands of messages, unlike most of today’s chat apps.
					</Text>
				</Box>
			</Box>
			<Components.QuarklycommunityKitPopup
				align-self="center"
				display="flex"
				align-items="center"
				justify-content="center"
				margin="5% 0px 5% 0px"
				onloadShow={false}
				sm-box-shadow="0 10px 15px -3px rgba(0, 0, 0, 0.1)"
				md-margin="-2% 0px 0px 0px"
				md-width="75%"
				sm-width="94%"
				sm-height="auto"
				sm-margin="0px 0px 0px 0px"
			>
				<Override
					slot="Button Open"
					border-radius="24PX"
					border-color="--color-orange"
					border-width="3px"
					border-style="outset"
					background="rgba(0, 119, 204, 0)"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					width="130%"
					min-width="130%"
					hover-box-shadow="0 0 15px 5px rgba(255, 124, 34, 0.53)"
					sm-min-width="90%"
					font="normal 300 16px/1.5 Beaufort for LOL"
					sm-box-shadow="0 0px 8px 3px --color-orange"
					md-box-shadow="0 0 7px 3px rgba(255, 124, 34, 0.58)"
				>
					WATCH INTRODUCTION VIDEO
				</Override>
				<Override
					slot="Wrapper"
					width="70%"
					border-radius="25px"
					background="#080d13"
					box-shadow="0 0px 35px 35px --color-dark"
					justify-content="center"
					align-items="center"
				/>
				<Override slot="Wrapper :open" border-radius="25px" />
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/juR99BO1g2w" autoplay={false} modestbranding={false} showOverlay={false} />
			</Components.QuarklycommunityKitPopup>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg,#080d13 1.9%,rgba(4, 8, 12, 0) 81.9%),linear-gradient(180deg,#080d13 3.3%,rgba(4, 8, 12, 0) 19%),rgba(255, 255, 255, 0) url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/adventurers%20league.jpg?v=2024-07-04T02:01:23.598Z) 0% 2%/100%"
			min-height="90vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="10% 0 10% 0"
			sm-background="linear-gradient(180deg,rgba(0,0,0,0) 41%,#080d13 100%),linear-gradient(0deg,rgba(0,0,0,0) 51.9%,#080d13 98.1%),rgba(255, 255, 255, 0) url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/magnific-VZAhErdEAvtZ3q3iNyoS-magnific-2EroYxPBUkKc94mbm7oM-magnific-GG1gkhUm2DSTqtER7Jmp-atomsk43_adventurers_league_medieval_fantasy_--ar_169_--sref__2624e6d3-7edb-44a3-a5c7-1df9c64b79c1_2-2.png?v=2024-07-03T12:05:41.515Z) 50% 0%/cover"
			md-background="linear-gradient(180deg,rgba(0,0,0,0) 68.1%,#080d13 100%),linear-gradient(0deg,rgba(0,0,0,0) 20%,#080d13 100%),rgba(255, 255, 255, 0) url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/adventurers%20league.jpg?v=2024-07-04T02:01:23.598Z) 50% 0%/cover"
			lg-background="linear-gradient(0deg,rgba(0,0,0,0) 22.4%,#080d13 100%),rgba(255, 255, 255, 0) url(https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/adventurers%20league.jpg?v=2024-07-04T02:01:23.598Z) 0% 2%/cover"
			sm-min-height="auto"
			align-items="center"
			justify-content="center"
			flex-direction="column"
			display="flex"
			height="auto"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				justify-content="center"
				sm-height="auto"
			>
				<Text
					margin="8% 0px 1% 0px"
					color="--light"
					font="normal 900 60px/1.2 Beaufort for LOL"
					lg-text-align="center"
					sm-font="normal 700 36px/1.2 Beaufort for LOL"
					text-align="center"
					width="80%"
					md-font="normal 900 44px/1.2 Beaufort for LOL"
					sm-width="100%"
					md-margin="30px 0px 24px 0px"
					text-shadow="0px 0px 5px --color-dark"
					sm-text-shadow="0px 0px 5px --color-dark"
					sm-margin="25% 0px 5% 0px"
				>
					JOIN THE{" "}
					<br />
					ADVENTURER'S LEAGUE
				</Text>
				<Text
					margin="2% 45px 3% 45px"
					color="#FFFFFF"
					font="normal 300 24px/1.5 Beaufort for LOL"
					lg-text-align="center"
					text-align="center"
					text-shadow="0 0px 1px --color-dark"
					mix-blend-mode="normal"
					md-width="96%"
					sm-font="normal 300 18px/1.5 Beaufort for LOL"
					sm-margin="0% 45px 5% 45px"
					padding="0px 5% 0px 5%"
				>
					Everweave is made by passionate D&D players with the support of the community, interested in seeing this project evolving in the right direction. Be part of a growing community to make this project the best it can be.{" "}
				</Text>
				<LinkBox
					align-items="center"
					justify-content="center"
					target="_blank"
					href="https://discord.gg/TAE7NJHqBp"
					sm-margin="5% 0px 5% 0px"
					margin="3% 0px 2% 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/discord.jpg?v=2024-07-04T01:39:53.671Z"
						display="block"
						object-fit="contain"
						width="60%"
						border-radius="24px"
						hover-box-shadow="0 0 15px 5px rgba(255, 124, 34, 0.58)"
						sm-border-radius="12px"
						sm-box-shadow="0 0 15px 0 --color-orange"
						md-box-shadow="0 0 10px 5px rgba(255, 124, 34, 0.65)"
						border-color="--color-orange"
						border-width="3px"
						border-style="outset"
						sm-width="90%"
						sm-margin="0px 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/discord.jpg?v=2024-07-04T01%3A39%3A53.671Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/discord.jpg?v=2024-07-04T01%3A39%3A53.671Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/discord.jpg?v=2024-07-04T01%3A39%3A53.671Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/discord.jpg?v=2024-07-04T01%3A39%3A53.671Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/discord.jpg?v=2024-07-04T01%3A39%3A53.671Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/discord.jpg?v=2024-07-04T01%3A39%3A53.671Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66777bc18d4a0c002030d07b/images/discord.jpg?v=2024-07-04T01%3A39%3A53.671Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="10% 0 10% 0"
			min-height="60vh"
			sm-min-height="auto"
			background="#080d13"
			display="flex"
			flex-direction="column"
			height="auto"
			sm-height="auto"
		>
			<Override
				slot="SectionContent"
				flex="1 1 auto"
				sm-align-items="center"
				sm-justify-content="center"
				align-items="center"
				justify-content="center"
				display="flex"
				flex-direction="column"
			/>
			<Hr
				min-height="10px"
				min-width="40%"
				margin="1% 0px 0px 0px"
				color="#ffffff"
				width="40%"
				align-items="center"
				display="flex"
				justify-content="center"
			/>
			<Hr
				min-height="10px"
				min-width="80%"
				margin="0.5% 0px 0px 0px"
				color="#ffffff"
				width="80%"
				display="flex"
				align-items="center"
				justify-content="center"
			/>
			<Hr
				min-height="10px"
				min-width="40%"
				margin="0.5% 0px 3% 0px"
				color="#ffffff"
				width="40%"
				display="flex"
				align-items="center"
				justify-content="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				align-items="center"
				justify-content="center"
				flex-direction="column"
			>
				<Text
					margin="3% 0px 2% 0px"
					color="--light"
					font="normal 900 60px/1.2 Beaufort for LOL"
					lg-text-align="center"
					sm-font="normal 700 36px/1.2 Beaufort for LOL"
					text-align="center"
					width="80%"
					md-font="normal 900 44px/1.2 Beaufort for LOL"
					sm-width="100%"
					md-margin="30px 0px 24px 0px"
					text-shadow="0px 0px 2px --color-dark"
					sm-text-shadow="0px 0px 5px --color-dark"
					display="flex"
					align-items="center"
					justify-content="center"
					sm-margin="0px 0px 5% 0px"
				>
					THE TOWN CRIER'S SCROLL
				</Text>
				<Box
					min-width="100px"
					min-height="15%"
					width="50%"
					display="flex"
					height="15%"
					sm-align-items="center"
					sm-justify-content="center"
					margin="2% 0px 3% 0px"
					sm-min-width="75%"
					sm-margin="4% 0px 5% 0px"
					quarkly-title="email form"
				>
					<Input
						display="block"
						placeholder-color="LightGray"
						background="transparent"
						border-width="3px"
						border-style="outset"
						border-color="--color-orange"
						border-radius="16px 0px 0px 16px"
						width="80%"
						placeholder="example@email.com"
						font="normal 300 16px/1.5 Beaufort for LOL"
						text-color="white"
						sm-width="80%"
						sm-min-width="80%"
						sm-border-radius="10px 0px 0px 10px"
						sm-border-width="2px"
						type = "email"
						value = {email}
						onChange = {e => setEmail(e.target.value)}
					/>
					<Button
						margin="0px 0px 0px 2%"
						border-radius="0px 16px 16px 0px"
						background="linear-gradient(-25deg,#ff9700 0%,rgba(0,0,0,0) 40.5%,rgba(0,0,0,0) 55.7%,#ff9700 100%),--color-secondary"
						font="normal 400 100%/1.5 Beaufort for LOL"
						color="#000000"
						border-width="3px"
						border-style="outset"
						border-color="--color-orange"
						sm-width="40%"
						sm-font="normal 400 15px/1.5 Beaufort for LOL"
						sm-text-align="center"
						sm-align-items="center"
						sm-display="flex"
						sm-justify-content="center"
						sm-border-radius="0px 10px 10px 0px"
						sm-border-width="1px"
						padding="auto auto auto auto"
						display="flex"
						align-items="center"
						justify-content="center"
						onClick = {sendEmail}
					>
						SUBSCRIBE
					</Button>
				</Box>
				<Text
					margin="0px 5% 32px 5%"
					color="#FFFFFF"
					font="normal 300 26px/1.5 Beaufort for LOL"
					lg-text-align="center"
					text-align="center"
					text-shadow="0 1px 4PX #000000"
					mix-blend-mode="normal"
					sm-margin="5% 1% 5% 1%"
					md-width="96%"
					sm-min-height="auto"
					sm-font="normal 300 19px/1.5 Beaufort for LOL"
					sm-max-width="94%"
					padding="0px 10% 0px 10%"
					sm-padding="0px 2% 0px 2%"
				>
					Everweave's journey is just beginning, evolving rapidly and we want you to stay in the loop!  E-mail subscribers receive regular updates about new features and state of the game as well first dibs on open playtest spots.
				</Text>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66777bc18d4a0c002030d079"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});
