import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "max-height": "",
        "background": "",
        "height": "",
        "min-height": "",
        "width": "",
        "max-width": "",
        "display": "block",
        "position": "static",
        "overflow-x": "visible",
        "overflow-y": "",
        "font": "normal 100 20px/1.5 Beaufort for LOL"
    },
    "index": {
        "max-height": "",
        "background": "",
        "height": "",
        "min-height": "",
        "width": "",
        "max-width": "",
        "display": "block",
        "position": "static",
        "overflow-x": "visible",
        "overflow-y": "",
        "font": "normal 100 20px/1.5 Beaufort for LOL"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
